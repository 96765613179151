// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-co-2-tsx": () => import("./../../../src/pages/co2.tsx" /* webpackChunkName: "component---src-pages-co-2-tsx" */),
  "component---src-pages-cookie-tsx": () => import("./../../../src/pages/cookie.tsx" /* webpackChunkName: "component---src-pages-cookie-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-thinwood-tsx": () => import("./../../../src/pages/thinwood.tsx" /* webpackChunkName: "component---src-pages-thinwood-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */)
}

