module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-R2MVX4ZDL0","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"G-R2MVX4ZDL0"},"facebookPixel":{"pixelId":""},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NDVH3QN","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"web"}},"routeChangeEventName":"mira-and-me-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-R2MVX4ZDL0","head":false,"anonymize":true,"respectDNT":true,"exclude":["/admin/**"],"pageTransitionDelay":0,"defer":true,"enableWebVitalsTracking":true,"cookieName":"gatsby-gdpr-google-analytics"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
